import { classifyService, platformPrintingMaterialService } from '@/api';
import { MaterialUnitEnum } from '@/resource/enum';

import { SupplierMaterialResource } from '@/resource/model';
import { messageError, translation } from '@/utils';
import { Form, Message } from 'element-ui';
import { Component, Prop, PropSync, Vue, Watch } from 'vue-property-decorator';

@Component({
  components: {}
})
export default class AddPrintingMaterial extends Vue {
  /**
   * dialog 显示状态
   */
  @PropSync('visible', { default: false }) public dialogVisible!: boolean;
  /**
   * 企业信息数据对象
   */
  @Prop({ required: false }) public material: SupplierMaterialResource | null = null;

  /**
   * dialog 标题
   */
  public title = '';
  /**
   * 提交loading状态
   */
  public submitLoading = false;

  /**
   * 分类数据
   */
  public categoryList: Array<{ label: string; value: number }> = [];

  /**
   * 表单字段栅格数
   */
  public formColSpan = 12;

  /**
   * 表单数据对象
   */
  public formData: {
    materialName: string;
    categoryIdList: Array<number> | null;
    categoryName?: string;
    materialNo: string;
    brand: string | null;
    calcUnit: MaterialUnitEnum | null;
    width: number | null;
    length: number | null;
    sortOrder: number | null;
    remark: string;
    companyId: number | null;
    categoryId: number | null;
    secondCategoryId: number | null;
  } = {
    materialName: '',
    categoryIdList: [],
    categoryName: '',
    materialNo: '',
    brand: '',
    calcUnit: 1,
    width: 0,
    length: 0,
    sortOrder: 0,
    remark: '',
    companyId: null,
    categoryId: null,
    secondCategoryId: null
  };

  /**
   * 表单验证规则
   */
  public formRules = {
    materialName: [
      {
        required: true,
        validator: (rule: any, value: string, callback: Function): void => {
          if (!value) {
            callback(new Error(translation('supplierMaterial.inputName')));
            return;
          }

          callback();
        },
        trigger: 'blur'
      },
      {
        validator: (rule: any, value: string, callback: Function): void => {
          platformPrintingMaterialService
            .checkMaterialNameRepeat(this.material?.id as number, value)
            .then((isRepeat: boolean) => {
              if (isRepeat) {
                callback(translation('requestError.materialNameRepeat'));
                return;
              }

              callback();
            })
            .catch(error => {
              messageError(error);
            });
        },
        trigger: 'blur'
      }
    ],
    materialNo: [
      {
        required: true,
        validator: (rule: any, value: string, callback: Function): void => {
          if (!value) {
            callback(new Error(translation('supplierMaterial.inputCode')));
            return;
          }

          callback();
        },
        trigger: 'blur'
      },
      {
        validator: (rule: any, value: string, callback: Function): void => {
          platformPrintingMaterialService
            .checkMaterialCodeRepeat(this.material?.id as number, value)
            .then((isRepeat: boolean) => {
              if (isRepeat) {
                callback(translation('requestError.materialCodeRepeat'));
                return;
              }

              callback();
            });
        },
        trigger: 'blur'
      }
    ],
    categoryIdList: [
      {
        required: true,
        validator: (rule: any, value: string, callback: Function): void => {
          if (!value || value.length <= 0) {
            callback(new Error(translation('supplierMaterial.selectCategory')));
            return;
          }

          callback();
        },
        trigger: 'blur'
      }
    ],
    calcUnit: [
      {
        required: true,
        validator: (rule: any, value: string, callback: Function): void => {
          if (!value) {
            callback(new Error(translation('supplierMaterial.selectUnit')));
            return;
          }

          callback();
        },
        trigger: 'blur'
      }
    ],
    width: [
      {
        required: true,
        validator: (rule: any, value: string, callback: Function): void => {
          if (!value && Number(value) !== 0) {
            callback(new Error(translation('supplierMaterial.inputWidth')));
            return;
          }

          callback();
        },
        trigger: 'blur'
      }
    ],
    length: [
      {
        required: true,
        validator: (rule: any, value: string, callback: Function): void => {
          if (!value && Number(value) !== 0) {
            callback(new Error(translation('supplierMaterial.inputLength')));
            return;
          }

          callback();
        },
        trigger: 'blur'
      }
    ]
  };

  /**
   * 操作
   */
  public isCreate: boolean | null = true;

  /**
   * 初始化标记
   */
  private isInitalized = false;

  /**
   * dialog开启事件
   */
  public handleDialogOpen(): void {
    this.init();

    if (!this.material) {
      this.isCreate = true;
      this.title = 'supplierMaterial.addMaterial';
      return;
    }

    this.isCreate = false;
    this.title = 'supplierMaterial.editMaterial';

    Object.assign(this.formData, this.material);
    this.formData.categoryIdList = [Number(this.formData.categoryId)];
    if (this.formData.secondCategoryId) {
      this.formData.categoryIdList.push(Number(this.formData.secondCategoryId));
    }
  }

  /**
   * 提交数据
   */
  public handleSubmit(): void {
    (this.$refs.form as Form).validate(async (valid: boolean) => {
      if (!valid) {
        return;
      }

      this.openSubmitLoading();

      try {
        this.formData.length = this.formData.length || 0;
        this.formData.width = this.formData.width || 0;

        this.formData.companyId = Number(this.$route.query.companyId);
        if (this.isCreate) {
          await platformPrintingMaterialService.post(this.formData as any);
          Message.success(translation('operationRes.addSuccess'));
          this.$emit('add-success');
        } else {
          await platformPrintingMaterialService.put(this.formData as any);
          Message.success(translation('operationRes.editSuccess'));
          this.$emit('edit-success');
        }

        this.closeDialog();
      } catch (error) {
        messageError(error);
      } finally {
        this.closeSubmitLoading();
      }
    });
  }

  /**
   * dialog关闭事件
   */
  public handleDialogClosed(): void {
    this.$emit('dialog-closed');
    this.resetForm();
    (this.$refs.form as Form).resetFields();
  }

  /**
   * 获取单位列表
   */
  public getUnitList(): Array<any> {
    const unitList: Array<any> = [];

    for (const key in MaterialUnitEnum) {
      if (!Number(key)) {
        unitList.push({
          label: translation(`materialUnit.${key}`),
          value: MaterialUnitEnum[key]
        });
      }
    }

    return unitList;
  }

  private resetForm(): void {
    this.formData = {
      materialName: '',
      categoryIdList: [],
      categoryName: '',
      materialNo: '',
      brand: '',
      calcUnit: 1,
      width: 0,
      length: 0,
      sortOrder: 0,
      remark: '',
      companyId: null,
      categoryId: null,
      secondCategoryId: null
    };
  }

  /**
   * 初始化dialog所需数据
   */
  private init(): void {
    // 判断是否已初始化
    if (this.isInitalized) return;

    this.loadCategoryData();
  }

  @Watch('formData.categoryIdList')
  private handleCategoryChanged(value: Array<number>): void {
    if (!value || value.length <= 0) {
      this.formData.categoryId = null;
      this.formData.secondCategoryId = null;
    } else {
      this.formData.categoryId = value[0];
      this.formData.secondCategoryId = value[1];
    }
  }

  /**
   * 载入分类数据
   */
  private async loadCategoryData(): Promise<void> {
    try {
      const categoryList = (await classifyService.getTreeData()) || [];
      this.categoryList = classifyService.handleCascaderOption(categoryList);
    } catch (error) {
      messageError(error);
    }
  }

  /**
   * 开启loading
   */
  private openSubmitLoading(): void {
    this.submitLoading = true;
  }

  /**
   * 关闭loading
   */
  private closeSubmitLoading(): void {
    this.submitLoading = false;
  }

  /**
   * 关闭dialog
   */
  private closeDialog(): void {
    this.dialogVisible = false;
  }
}
