import { SupplierMaterialResource, ImportRes } from '@/resource/model';
import { Component, Vue, Watch } from 'vue-property-decorator';
import { OsTable, OsTableQuery, OsPagination, OsTableOperation } from '@/components';
import { OsTableColumn, OsTableOption, RowOperation } from '@/components/os-table/os-table';
import { Paging } from '@/api/base';
import { OperationOption } from '@/components/os-table-operation/os-table-operation';
import {
  downloadFileByBlob,
  getStatusClass,
  getStatusI18Key,
  handleImportError,
  messageError,
  showWarningConfirm,
  translation
} from '@/utils';
import { ResourceStatusEnum, MaterialUnitEnum } from '@/resource/enum';
import { ApiResponse } from '@/api/axios';
import { classifyService, platformPrintingMaterialService } from '@/api';
import { Message } from 'element-ui';
import { ImportFile } from '@/views/dialogs/index';
import { OsQueryItemOption } from '@/components/os-table-query/os-table-query';
import AddPrintingMaterial from './add-printing-material/add-printing-material.vue';
import { dateFormat } from '@/filters';

@Component({
  components: {
    OsTable,
    OsTableQuery,
    OsPagination,
    OsTableOperation,
    ImportFile,
    AddPrintingMaterial
  }
})
export default class PrintingMaterial extends Vue {
  /**
   * 表格配置
   */
  public tableOption: OsTableOption<SupplierMaterialResource> = {
    loading: false,
    data: [],
    fit: true
  };
  /**
   * 表格列配置
   */
  public tableColumnOptions: Array<OsTableColumn<SupplierMaterialResource>> = [
    {
      type: 'selection',
      prop: 'id',
      label: '',
      reserveSelection: true,
      fixed: true
    },
    {
      prop: 'materialNo',
      label: 'supplierMaterial.code',
      minWidth: '150px',
      showOverflowTooltip: true,
      fixed: true
    },
    {
      prop: 'materialName',
      label: 'supplierMaterial.name',
      minWidth: '150px',
      showOverflowTooltip: true
    },
    {
      prop: 'categoryName',
      label: 'supplierMaterial.category',
      minWidth: '150px',
      showOverflowTooltip: true,
      formatter: (row: Object): string => {
        const data: SupplierMaterialResource = row as SupplierMaterialResource;

        const list = [data.categoryName];
        if (data.secondCategoryName) {
          list.push(data.secondCategoryName);
        }

        return list.join(' / ');
      }
    },
    {
      prop: 'brand',
      label: 'supplierMaterial.brand',
      minWidth: '150px',
      showOverflowTooltip: true
    },
    {
      prop: 'length',
      label: 'supplierMaterial.size',
      minWidth: '150px',
      showOverflowTooltip: true,
      formatter: (row: object): string => {
        const material = row as SupplierMaterialResource;
        return `
        ${material.width} x ${material.length}
      `;
      }
    },
    {
      prop: 'calcUnit',
      label: 'supplierMaterial.unit',
      minWidth: '150px',
      showOverflowTooltip: true,
      formatter: (row: object): string => {
        const material = row as SupplierMaterialResource;

        return translation(`materialUnit.${MaterialUnitEnum[material.calcUnit]}`);
      }
    },
    {
      prop: 'sortOrder',
      label: 'supplierMaterial.displayOrder',
      minWidth: '100px',
      showOverflowTooltip: true
    },
    {
      prop: 'status',
      label: 'common.status',
      minWidth: '150px',
      showOverflowTooltip: true
    },
    {
      prop: 'remark',
      label: 'common.remark',
      minWidth: '150px',
      showOverflowTooltip: true
    },
    {
      prop: 'createTime',
      label: 'common.createTime',
      minWidth: '200px',
      showOverflowTooltip: true
    }
  ];
  /**
   * 查询表单数据对象
   */
  public queryForm: Partial<{
    keywords: string;
    companyId: number | null;
    categoryId: number | null;
    status: ResourceStatusEnum | null;
  }> = {
    keywords: '',
    companyId: Number(this.$route.query.companyId),
    categoryId: null,
    status: null
  };
  /**
   * 表格查询表单配置
   */
  public queryFormOptions: Array<OsQueryItemOption> = [
    {
      type: 'Input',
      field: 'keywords',
      label: 'common.keyword',
      option: {
        placeholder: 'common.inputKeyWord'
      }
    },
    {
      type: 'Cascader',
      field: 'categoryId',
      label: 'supplierMaterial.category',
      option: {
        placeholder: 'supplierMaterial.selectCategory',
        filterable: true,
        clearable: true,
        collapseTags: true,
        showAllLevels: false,
        props: {
          checkStrictly: true,
          emitPath: false
        }
      },
      optionData: []
    },
    {
      type: 'Select',
      field: 'status',
      label: 'common.status',
      option: {
        placeholder: 'common.selectStatus'
      },
      optionData: (): Array<any> => {
        return [
          {
            label: translation('common.startUsing'),
            value: 1
          },
          {
            label: translation('common.disabled'),
            value: 2
          }
        ];
      }
    }
  ];
  /**
   * 表格操作栏
   */
  public tableToolbarOptions: Array<OperationOption> = [
    {
      type: 'primary',
      slot: 'start',
      label: 'button.add',
      operationType: 'add',
      icon: 'el-icon-circle-plus-outline',
      permissionCode: 'base:printing:material:save',
      handleClick: (): void => {
        this.openDialog();
      }
    },
    {
      type: 'primary',
      slot: 'start',
      label: 'button.import',
      operationType: 'import',
      icon: 'el-icon-upload2',
      permissionCode: 'base:printing:material:importData',
      handleClick: (): void => {
        this.openImportFileDialog();
      }
    },
    {
      type: 'danger',
      slot: 'start',
      label: 'button.delete',
      operationType: 'delete',
      icon: 'el-icon-delete',
      plain: true,
      disabled: true,
      permissionCode: 'base:printing:material:batchDelete',
      handleClick: (): void => {
        this.batchDelete();
      }
    },
    {
      type: 'primary',
      slot: 'start',
      label: 'button.export',
      operationType: 'export',
      icon: 'el-icon-download',
      permissionCode: 'base:printing:material:exportData',
      loading: false,
      handleClick: (): void => {
        this.export();
      }
    },
    {
      type: 'primary',
      slot: 'end',
      label: 'button.using',
      operationType: 'using',
      icon: 'el-icon-open',
      disabled: true,
      permissionCode: 'base:printing:material:editStatus',
      handleClick: (): void => {
        this.batchUpdateStatus(ResourceStatusEnum.using);
      }
    },
    {
      type: 'danger',
      slot: 'end',
      label: 'button.disabled',
      operationType: 'disabled',
      icon: 'el-icon-turn-off',
      plain: true,
      disabled: true,
      permissionCode: 'base:printing:material:editStatus',
      handleClick: (): void => {
        this.batchUpdateStatus(ResourceStatusEnum.disabled);
      }
    }
  ];
  /**
   * 列操作配置
   */
  public rowOperationOption: RowOperation<SupplierMaterialResource> = {
    width: '150px',
    operations: [
      {
        operationType: 'edit',
        type: 'text',
        label: 'button.edit',
        icon: 'el-icon-edit',
        permissionCode: 'base:printing:material:edit',
        handleClick: (row: SupplierMaterialResource): void => {
          this.openDialog(row);
        }
      },
      {
        operationType: 'delete',
        type: 'text',
        label: 'button.delete',
        icon: 'el-icon-delete',
        permissionCode: 'base:printing:material:delete',
        handleClick: (row: SupplierMaterialResource): void => {
          this.delete(row);
        }
      }
    ]
  };
  /**
   * 分页配置
   */
  public pagintaionOption: Paging = {
    currentPage: 1,
    showCount: 10
  };
  /**
   * 表格总记录数
   */
  public totalData = 0;
  /**
   * dialog显示状态
   */
  public dialogVisible = false;
  /**
   * 编辑数据对象
   */
  public editRow: SupplierMaterialResource | null = null;
  /**
   * 导入dialog显示状态控制
   */
  public importDialogVisible = false;
  /**
   * 导入dialog 标题
   */
  public importTitle = 'supplierMaterial.import';
  /**
   * 表格选中数据
   */
  private selectedRows: Array<SupplierMaterialResource> = [];
  /**
   * 查询按钮点击事件
   */
  public handleQueryClick(): void {
    this.loadData();
  }
  /**
   * 分页事件
   */
  public handlePagination(): void {
    this.loadData();
  }
  /**
   * 表格行选中事件
   */
  public handleSelectionChange(rows: Array<SupplierMaterialResource>): void {
    this.selectedRows = rows;
  }
  /**
   * 加载表格数据
   */
  public loadData(): void {
    this.tableOption.loading = true;
    platformPrintingMaterialService
      .getList(this.queryForm as any, this.pagintaionOption)
      .then((res: { data: SupplierMaterialResource[]; total: number }) => {
        this.tableOption.data = res.data;
        this.totalData = res.total;
      })
      .catch((error: string) => {
        Message.error(error);
      })
      .finally(() => {
        this.tableOption.loading = false;
      });
  }
  /**
   * 重新加载
   */
  public reload(): void {
    this.pagintaionOption.currentPage = 1;
    (this.$refs.materialTable as OsTable).clearSelection();
    this.selectedRows = [];
    this.loadData();
  }
  public getStatusClass(status: number): string {
    return getStatusClass(status);
  }
  public getStatusI18Key(status: number): string {
    return getStatusI18Key(status);
  }
  /**
   * vue创建完成钩子
   */
  public created(): void {
    this.loadMaterialCategories();
    this.loadData();
  }
  /**
   * dialog关闭事件
   */
  public handleDialogClosed(): void {
    this.editRow = null;
  }

  /**
   * 批量更新状态
   * @param status 状态
   */
  public batchUpdateStatus(status: ResourceStatusEnum): void {
    const idList = this.selectedRows.map(x => x.id);
    platformPrintingMaterialService.batchUpdateStatus(idList, status).then(() => {
      this.reload();
      Message.success(
        status === ResourceStatusEnum.using ? translation('common.usingSuccess') : translation('common.disabledSuccess')
      );
    });
  }
  /**
   * 下载导入模板
   */
  public downloadTemplate(): void {
    (this.$refs.importDialog as ImportFile).setDownloadLoading(true);
    platformPrintingMaterialService
      .downloadTemplate()
      .then((blob: any) => {
        downloadFileByBlob(translation('supplierMaterial.printingTemplateName'), blob);
      })
      .catch((error: string) => {
        Message.error(error);
      })
      .finally(() => {
        (this.$refs.importDialog as ImportFile).setDownloadLoading(false);
      });
  }
  /**
   * 上传成功事件
   */
  public handleImportSuccess(path: string): void {
    const companyId = this.$route.query.companyId as string;

    platformPrintingMaterialService
      .import(Number(companyId), path)
      .then(() => {
        this.reload();
        Message.success(translation('dialog.importSuccess'));
        this.importDialogVisible = false;
      })
      .catch((error: ApiResponse<ImportRes>) => {
        handleImportError(error);
        (this.$refs.importDialog as ImportFile).setLoading(false);
      });
  }

  @Watch('selectedRows')
  private handleSelectedChanged(value: Array<SupplierMaterialResource>): void {
    const allowedOpationArray = ['add', 'import', 'export'];
    this.tableToolbarOptions.forEach(button => {
      if (allowedOpationArray.indexOf(button.operationType) === -1) {
        button.disabled = value.length === 0;
      }
    });
    this.tableToolbarOptions = [...this.tableToolbarOptions];
  }
  /**
   * 删除
   */
  private delete(row: SupplierMaterialResource): void {
    showWarningConfirm(translation('tip.confirmDelete'))
      .then(async () => {
        try {
          await platformPrintingMaterialService.delete(row.id);
          Message.success(translation('operationRes.deleteSuccess'));
          this.reload();
        } catch (error) {
          messageError(error);
        }
      })
      .catch(() => {
        Message.info(translation('operationRes.cancelDelete'));
      });
  }
  /**
   * 批量删除
   */
  private batchDelete(): void {
    showWarningConfirm(translation('tip.confirmDelete'))
      .then(async () => {
        try {
          const idList = this.selectedRows.map(row => row.id);
          await platformPrintingMaterialService.batchDelete(idList);
          Message.success(translation('operationRes.deleteSuccess'));
          this.reload();
        } catch (error) {
          messageError(error);
        }
      })
      .catch(() => {
        Message.info(translation('operationRes.cancelDelete'));
      });
  }
  /**
   * 打开dialog
   * @param row 编辑数据
   */
  private openDialog(row: SupplierMaterialResource | null = null): void {
    this.editRow = row;
    this.dialogVisible = true;
  }
  /**
   * 打开导入窗口
   */
  private openImportFileDialog(): void {
    this.importDialogVisible = true;
  }

  /**
   * 获取材料分类
   */
  private async loadMaterialCategories(): Promise<void> {
    const categoryList = await classifyService.getTreeData();

    const queryOptionItem = this.queryFormOptions.find(option => option.field === 'categoryId');

    if (queryOptionItem) {
      queryOptionItem.optionData = classifyService.handleCascaderOption(categoryList);
    }
  }

  /**
   * 导出数据
   */
  private async export(): Promise<void> {
    const option = this.tableToolbarOptions.find(option => option.operationType === 'export');
    option!.loading = true;

    const exportCondition = {
      ...this.queryForm,
      materialIdList: this.selectedRows.map(row => row.id)
    } as any;

    try {
      const blob = await platformPrintingMaterialService.export(exportCondition);

      downloadFileByBlob(
        `${translation('supplierMaterial.exportPrintingMaterial')}-${dateFormat(new Date(), 'YYYYMMDDHHmmss')}.xlsx`,
        blob
      );
    } catch (error) {
      messageError(error);
    } finally {
      option!.loading = false;
    }
  }
}
